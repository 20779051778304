@import '../../scss/abstracts';

.view {
  @extend %hintText;
}

.winner {
  @extend %hintColorAttention;
}

.error {
  @extend %hintColorAlert;
}
