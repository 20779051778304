@import '../../scss/abstracts';

.view {
  flex: 1 0 auto;
}

.status {
  max-width: 80vw;
}

.newGame {
  @extend %hintText;
  @extend %hintColorNeutral;
}
