@import './variables';

%hintText {
  font-size: 2rem;
  line-height: 3rem;
  max-width: 70vw;
}

%hintColorNeutral {
  color: darkgrey;
}

%hintColorAttention {
  color: darkseagreen;
}

%hintColorAlert {
  color: darkred;
}
