@import-normalize;

@import './scss/_vendor';

html,
body,
#root {
  height: 100%;
}

body {
  min-width: 320px;
}
